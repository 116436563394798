import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carouselBlocks = document.querySelectorAll('.programs-carousel-block');

  if (0 < carouselBlocks.length) {
    carouselBlocks.forEach((carouselBlock) => {
      new Swiper(
        carouselBlock.querySelector('.programs-carousel-block__carousel'),
        {
          spaceBetween: 20,
          slidesPerView: 1,
          loop: true,

          breakpoints: {
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1080: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            1400: {
              slidesPerView: 3,
              spaceBetween: 80,
            },
          },

          navigation: {
            prevEl: carouselBlock.querySelector('.swiper-button-prev'),
            nextEl: carouselBlock.querySelector('.swiper-button-next'),
          },

          pagination: {
            el: carouselBlock.querySelector('.swiper-pagination'),
            clickable: true,
          },
        }
      );
    });
  }
});
